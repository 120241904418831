<template>
	<div>
		<NetworkGraph :serverId="serverId" :networkId="networkId" />
		<NetworkRemotesTable :remotes="inNetwork" />
		<NetworkMap
			v-if="loadedRemotes"
			:inNetwork="inNetwork"
			:outOfNetwork="outOfNetwork"
			:solarOutage="solarOutage"
			:serverId="serverId"
			:networkId="networkId"
		/>
		<NetworkHeatMap :serverId="serverId" :networkId="networkId" />
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { mapGetters } from 'vuex';

export default {
	name: 'Network',
	components: {
		NetworkGraph: () => import('@/view/content/widgets/network/NetworkGraph.vue'),
		NetworkRemotesTable: () => import('@/view/content/widgets/network/NetworkRemotesTable.vue'),
		NetworkMap: () => import('@/view/content/widgets/network/NetworkMap.vue'),
		NetworkHeatMap: () => import('@/view/content/widgets/network/NetworkHeatMap.vue'),
	},
	props: ['serverId', 'networkId'],
	data() {
		return {
			loadedRemotes: false,
			network: null,
			inNetwork: [],
			outOfNetwork: [],
			solarOutage: null,
		};
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	created() {
		if (this.currentUser.role !== 'admin') {
			this.$router.push({ name: 'dash' });
		}
		this.$http.get('idirect/networkslist').then(resp => {
			this.network = resp.data.data.find(net => net.server_id == this.serverId && net.NetworkId == this.networkId);
			this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Network - ' + this.network.NetworkName }]);
		});
		this.$http.get(`idirect/network/${this.serverId}/${this.networkId}/remotes`).then(resp => {
			this.inNetwork = resp.data.data.in_network;
			this.outOfNetwork = resp.data.data.elsewhere;
			this.solarOutage = resp.data.data.solar_outage_lat;
			this.loadedRemotes = true;
		});
	},
};
</script>

<style></style>
